document.addEventListener('DOMContentLoaded', function () {
    const DESKTOP_MIN_WIDTH = 1440;
    const MIN_SCROLL = 10;

    const header = document.querySelector('.header');
    const bodyBg = document.querySelector('.body__bg');
    const formSection = document.querySelector('.form');
    const modalIntro = document.querySelector('.modal-intro');
    const mainForm = document.querySelector('#mailform');
    const mainFormButton = document.querySelector('#mailform .form__button');
    const headerRequestButton = document.querySelector('.header__request');
    const complexItems = document.querySelectorAll('.complex__item:not(:first-of-type)');
    const requestAccessButton = document.getElementById('requestAccess');
    const modalForm = document.querySelector('.modal-form');
    const modalFormCloseBtn = document.querySelector('.modal-form__button-close');
    const modalFormBg = document.querySelector('.modal-form__bg');
    const modalMap = document.querySelector('.contacts-main');
    const notificationEl = document.querySelector('.notification');
    const tariffsSlides = document.querySelectorAll('.privates-tariffs__text-wrapper');
    const headerToggleOpen = header.querySelector('.header__nav-open');
    const headerNav = header.querySelector('.header__nav');
    const headerNavClose = headerNav.querySelector('.navigation__close');

    const openNavigation = function () {
        headerNav.classList.add('header__nav--opened');
        bodyBg.classList.add('body__bg--opened');
        document.body.style.overflow = 'hidden';
    };

    const closeNavigation = function () {
        headerNav.classList.remove('header__nav--opened');
        bodyBg.classList.remove('body__bg--opened');
        document.body.style.overflow = 'auto';
    };

    const fixHeader = function () {
        const scrolledFromTop = window.pageYOffset;
        if (scrolledFromTop > MIN_SCROLL) {
            header.classList.add('header--fixed');
        } else {
            header.classList.remove('header--fixed');
        }
    };

    const scrollToForm = function () {
        formSection.scrollIntoView({
            behavior: 'smooth',
        });
    };

    if (formSection) {
        headerRequestButton.addEventListener('click', scrollToForm);
    }

    headerToggleOpen.addEventListener('click', openNavigation);
    headerNavClose.addEventListener('click', closeNavigation);
    bodyBg.addEventListener('click', closeNavigation);

    if (window.innerWidth >= DESKTOP_MIN_WIDTH) {
        window.addEventListener('scroll', fixHeader);
        window.addEventListener('load', fixHeader);
    }

    if (tariffsSlides) {
        for (let i = 0; i < tariffsSlides.length; i++) {
            tariffsSlides[i].addEventListener('click', function () {
                for (let j = 0; j < tariffsSlides.length; j++) {
                    tariffsSlides[j].classList.remove('privates-tariffs__text-wrapper--active');
                }
                tariffsSlides[i].classList.add('privates-tariffs__text-wrapper--active');
            });
        }
    }

    if (complexItems && window.innerWidth < DESKTOP_MIN_WIDTH) {
        for (let i = 0; i < complexItems.length; i++) {
            const complexItemHeader = complexItems[i].querySelector('.card__title');
            const complexItemImage = complexItems[i].querySelector('.card__picture');
            const complexHiddenItem = complexItems[i].querySelector('.complex__hidden-item');
            complexItems[i].addEventListener('click', function () {
                if (!complexItems[i].classList.contains('active')) {
                    complexHiddenItem.classList.add('fadeInRightBig');
                    complexHiddenItem.classList.add('animated');
                    complexHiddenItem.classList.remove('fadeOutRightBig');
                    complexItems[i].classList.add('active');
                } else {
                    complexHiddenItem.classList.remove('fadeInRightBig');
                    complexHiddenItem.classList.add('fadeOutRightBig');
                    complexItems[i].classList.remove('active');
                }
            });
        }
    }

    if (modalMap) {
        const modalMapClose = modalMap.querySelector('.contacts-main__modal-map-close');
        const modalMapOpen = modalMap.querySelector('.contacts-main__button');
        modalMapOpen.addEventListener('click', () => {
            modalMap.querySelector('.contacts-main__modal-map').classList.add('show');
        });
        modalMapClose.addEventListener('click', () => {
            modalMap.querySelector('.contacts-main__modal-map').classList.remove('show');
        });
    }

    const hideModal = function (modalWindow, openedClass, modalCloseButtonClass) {
        if (modalWindow.classList.contains(openedClass)) {
            document.body.style.overflow = 'hidden';
        }
        const modalClose = modalWindow.querySelector(modalCloseButtonClass);
        modalClose.addEventListener('click', () => {
            modalWindow.classList.remove(openedClass);
            document.body.style.overflow = 'auto';
        });
    };

    if (mainForm) {
        const userAgreement = mainForm.querySelector('#agreement');
        userAgreement.addEventListener('change', function () {
            if (this.checked) {
                mainFormButton.disabled = false;
            } else {
                mainFormButton.disabled = true;
            }
        });
    }

    const showModalForm = () => modalForm.classList.add('modal-form--show');
    const hideModalForm = () => {
        const modalFormButton = modalForm.querySelector('.modal-form .form__button');
        const modalHiddenField = document.querySelector('#hidden-m');
        modalForm.classList.remove('modal-form--show');
        modalForm.querySelector('form').reset();
        modalFormButton.disabled = true;
        if (modalHiddenField) modalHiddenField.value = '';
        modalForm.querySelector('.form__textarea').removeAttribute('readonly');
    };

    if (modalForm) {
        const modalFormButton = modalForm.querySelector('.modal-form .form__button');
        const userAgreementModal = modalForm.querySelector('#agreement-m');

        userAgreementModal.addEventListener('change', function () {
            this.checked ? (modalFormButton.disabled = false) : (modalFormButton.disabled = true);
        });

        modalFormCloseBtn.addEventListener('click', hideModalForm);
        modalFormBg.addEventListener('click', hideModalForm);
    }

    const costList = document.querySelector('.prices__list');
    if (costList) {
        const modalHiddenField = document.querySelector('#hidden-m');

        costList.addEventListener('click', function (evt) {
            if (evt.target.classList.contains('prices__link')) {
                showModalForm();
                const productName = evt.target.closest('.prices__item').querySelector('.prices__name').textContent;
                modalHiddenField.value = productName;
            }
        });
    }

    if (modalForm) {
        modalForm.classList.remove('modal-form--show');
        modalForm.querySelector('form').reset();
        modalForm.querySelector('.form__textarea').removeAttribute('readonly');
    }

    if (modalIntro) {
        hideModal(modalIntro, 'modal-intro--show', '.modal-intro__button-close');
    }
    if (modalForm) {
        hideModal(modalForm, 'modal-form--show', '.modal-form__button-close');
        hideModal(modalForm, 'modal-form--show', '.modal-form__bg');
    }

    const modalFormDemo = document.querySelector('.modal-form--demo');
    if (modalFormDemo && requestAccessButton) {
        const modalHiddenField = modalFormDemo.querySelector('#hidden-m-demo');
        requestAccessButton.addEventListener('click', function () {
            modalFormDemo.classList.add('modal-form--show');
            modalHiddenField.value = 'Прошу предоставить доступ к демо-версии АСУ "УССМП"';
            modalFormDemo.querySelector('.form__textarea').value = 'Прошу предоставить доступ к демо-версии АСУ "УССМП"';
            modalFormDemo.querySelector('.form__textarea').setAttribute('readonly', true);
        });
    }

    const modalFormDemoButton = document.querySelector('.modal-form--demo .form__button');
    const modalFormDemoCloseBtn = document.querySelector('.modal-form--demo .modal-form__button-close');
    const modalFormDemoBg = document.querySelector('.modal-form--demo .modal-form__bg');

    if (modalFormDemo) {
        const userAgreement = modalFormDemo.querySelector('#agreement-m-demo');
        userAgreement.addEventListener('change', function () {
            if (this.checked) {
                modalFormDemoButton.disabled = false;
            } else {
                modalFormDemoButton.disabled = true;
            }
        });
    }

    if (notificationEl) {
        const notificationBg = document.querySelector('.notification__bg');
        const notificationCloseBtn = document.querySelector('.notification__button-close');
        const notificationMain = notificationEl.querySelector('.notification__main');
        const notificationResult = notificationEl.querySelector('.notification__result');
        const notificationText = notificationEl.querySelector('.notification__text');

        notificationCloseBtn.addEventListener('click', function () {
            notificationEl.classList.remove('notification--showed');
            if (modalForm) {
                modalForm.classList.remove('modal-form--show');
                modalForm.querySelector('.form__textarea').removeAttribute('readonly');
            }
        });

        notificationBg.addEventListener('click', function () {
            notificationEl.classList.remove('notification--showed');
            if (modalForm) {
                modalForm.classList.remove('modal-form--show');
                modalForm.querySelector('.form__textarea').removeAttribute('readonly');
            }
        });

        window.sendMail_after_hook_success = function (submiEvt) {
            notificationEl.classList.add('notification--showed');
            notificationMain.classList.remove('notification__main--error');
            notificationMain.classList.add('notification__main--success');
            notificationResult.textContent = 'Отправлено!';
            notificationText.textContent = 'Мы свяжемся с вами в ближайшее время.';
            console.log(submiEvt);
        };

        window.sendMail_after_hook_error = function (submiEvt) {
            notificationEl.classList.add('notification--showed');
            notificationMain.classList.remove('notification__main--success');
            notificationMain.classList.add('notification__main--error');
            notificationResult.textContent = 'Ошибка';
            notificationText.innerHTML = 'Пожалуйста, попробуйте снова, или позвоните нам:<br><a href="tel:+74732000473">+7 (473) 200‐04‐73</a>';
            console.log(submiEvt);
        };
    }

    const dotNames = document.querySelectorAll('.states-intro__dot-name');
    for (let i = 0; i < dotNames.length; i++) {
        dotNames[i].addEventListener('mouseover', (evt) => {
            evt.target.parentElement.querySelector('.states-intro__dot').classList.add('states-intro__dot--hovered');
        });
        dotNames[i].addEventListener('mouseleave', (evt) => {
            evt.target.parentElement.querySelector('.states-intro__dot').classList.remove('states-intro__dot--hovered');
        });
    }

    const inputSelect = document.querySelector('.form__input--select');
    if (inputSelect) {
        inputSelect.addEventListener('change', () => {
            if (inputSelect.value !== '') {
                inputSelect.style.color = 'rgba(0,0,0,0.8)';
            }
        });
    }

    if (modalFormDemo) {
        const hideDemoModalForm = () => {
            modalFormDemo.classList.remove('modal-form--show');
            modalFormDemo.querySelector('form').reset();
            modalFormDemoButton.disabled = true;
            inputSelect.value = '';
            inputSelect.style.color = 'rgba(0,0,0,0.5)';
        };

        modalFormDemoCloseBtn.addEventListener('click', hideDemoModalForm);
        modalFormDemoBg.addEventListener('click', hideDemoModalForm);
    }

    const sliderRecommendations = new Swiper('.recommendations__slider', {
        slidesPerView: 1,
        loop: true,
        breakpoints: {
            600: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1220: {
                slidesPerView: 4,
            },
            1440: {
                slidesPerView: 5,
            },
            1920: {
                slidesPerView: 6,
            },
        },
        navigation: {
            nextEl: '.recommendations__slider-next',
            prevEl: '.recommendations__slider-prev',
        },
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
    });

    const sliderClients = new Swiper('.clients__slider', {
        slidesPerView: 1,
        breakpoints: {
            600: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1220: {
                slidesPerView: 4,
            },
            1440: {
                slidesPerView: 5,
            },
            1920: {
                slidesPerView: 6,
            },
        },
        loop: true,
        navigation: {
            nextEl: '.clients__slider-next',
            prevEl: '.clients__slider-prev',
        },
        spaceBetween: 15,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
    });

    const sliderPrivateCalls = new Swiper('.privates-calls__slider', {
        slidesPerView: 1,
        loop: true,
        navigation: {
            nextEl: '.privates-calls__slider-next',
            prevEl: '.privates-calls__slider-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 5,
                allowSlidePrev: false,
                allowSlideNext: false,
            },
        },
    });

    const sliderPrivateTariffs = new Swiper('.privates-tariffs__slider', {
        slidesPerView: 1.2,
        spaceBetween: 12,
        loop: true,
        navigation: {
            nextEl: '.privates-tariffs__slider-next',
            prevEl: '.privates-tariffs__slider-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 2.2,
            },
            1220: {
                slidesPerView: 3.2,
            },
            1440: {
                slidesPerView: 4,
                allowSlidePrev: false,
                allowSlideNext: false,
            },
        },
    });

    const sliderStatesCalls = new Swiper('.states-calls__slider', {
        slidesPerView: 1,
        loop: true,
        navigation: {
            nextEl: '.states-calls__slider-next',
            prevEl: '.states-calls__slider-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 5,
                allowSlidePrev: false,
                allowSlideNext: false,
            },
        },
    });

    const sliderMobileWorkplace = new Swiper('.mobile-workplace__slider', {
        slidesPerView: 1,
        loop: true,
        navigation: false,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        autoplay: {
            delay: 5000,
        },
    });

    const toggleSliderMovement = (sliderEl, sliderObj) => {
        if (sliderEl) {
            sliderEl.addEventListener('mouseover', function () {
                sliderObj.autoplay.stop();
            });
            sliderEl.addEventListener('mouseout', function () {
                sliderObj.autoplay.start();
            });
        }
    };

    const sliderRecommendationsEl = document.querySelector('.recommendations__slider');
    const sliderClientsEl = document.querySelector('.clients__slider');
    toggleSliderMovement(sliderRecommendationsEl, sliderRecommendations);
    toggleSliderMovement(sliderClientsEl, sliderClients);

    const showBlocks = (el) => {
        el.style.visibility = 'visible';
        el.style.opacity = 1;
    };

    const indexIntroBlock = document.querySelector('.index-intro');
    if (indexIntroBlock) {
        setTimeout(() => {
            showBlocks(indexIntroBlock);
        }, 100);
    }

    const aboutIntroBlock = document.querySelector('.about-intro');
    const aboutDirectionsBlock = document.querySelector('.about-directions');
    if (aboutIntroBlock) {
        setTimeout(() => {
            showBlocks(aboutIntroBlock);
        }, 100);
        setTimeout(() => {
            showBlocks(aboutDirectionsBlock);
        }, 100);
    }

    const statesIntroBlock = document.querySelector('.states-intro');
    const statesNumbersBlock = document.querySelector('.states-numbers');
    if (statesIntroBlock) {
        setTimeout(() => {
            showBlocks(statesIntroBlock);
        }, 100);
        setTimeout(() => {
            showBlocks(statesNumbersBlock);
        }, 100);
    }

    const privatesIntroBlock = document.querySelector('.privates-intro');
    const privatesCallsBlock = document.querySelector('.privates-calls');
    if (privatesIntroBlock) {
        setTimeout(() => {
            showBlocks(privatesIntroBlock);
        }, 100);
        setTimeout(() => {
            showBlocks(privatesCallsBlock);
        }, 100);
    }

    const usefulMainBlock = document.querySelector('.useful-main');
    if (usefulMainBlock) {
        setTimeout(() => {
            showBlocks(usefulMainBlock);
        }, 100);
    }

    const contactsMainBlock = document.querySelector('.contacts-main');
    if (contactsMainBlock) {
        setTimeout(() => {
            showBlocks(contactsMainBlock);
        }, 100);
    }
});
